<template>
  <div data-cy="allowance">
    <v-snackbar
      :timeout="5000"
      :value="true"
      top
      :color="isError ? 'error' : 'success'"
      center
      v-model="snackBar"
    >
      {{ snackBarText }}
    </v-snackbar>
    <div v-if="isloading">
      <spinner></spinner>
    </div>
    <!-- search -->
    <v-row data-cy="search-field">
      <search-input-and-button
        data-cy-input="search-input-field"
        data-cy-btn="search-btn"
        label="Search by Name"
        @search="searchAllowance"
        @reset-input="resetData"
      ></search-input-and-button>
      <v-col class="mt-n3 ml-n3"> </v-col>
    </v-row>
    <div class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-5 text-right"
        @click="addNewItem"
        data-cy="addAllowance"
      >
        ADD ALLOWANCE
      </v-btn>
    </div>
    <v-data-table
      data-cy="allowance-table"
      :headers="headers"
      :items="allowances"
      class="elevation-1"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
      disable-sort
    >
      <template v-slot:top>
        <v-dialog
          data-cy="create-allowance-dialog"
          v-model="dialog"
          max-width="500px"
        >
          <v-form
            data-cy="allowance-form"
            lazy-validation
            v-model="valid"
            @submit.prevent="submitAllowance"
            ref="allowanceForm"
          >
            <v-card>
              <v-card-title class="text-h5 py-5 justify-center">
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        :items="tags"
                        item-value="tag"
                        item-text="tag"
                        outlined
                        :rules="[selectionRequired('Allowance Type')]"
                        dense
                        v-model="allowanceForm.description"
                        label="Select Allowance"
                        data-cy="selectAllowance"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        outlined
                        dense
                        prefix="GH₵"
                        :rules="[
                          required('Total Amount'),
                          currencyFormat(allowanceForm.totalAllowance),
                        ]"
                        v-model="allowanceForm.totalAllowance"
                        label="Total Amount"
                        data-cy="selectAmount"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-menu
                        data-cy="allowance-start-date"
                        ref="startMenu"
                        v-model="startMenu"
                        :close-on-content-click="false"
                        :return-value.sync="allowanceForm.startDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            data-cy="allowance-start-date-input"
                            outlined
                            dense
                            v-model="allowanceForm.startDate"
                            :rules="[required('Start Month')]"
                            label="Start Month"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          data-cy="allowance-start-date-picker"
                          v-model="allowanceForm.startDate"
                          type="month"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            data-cy="cancelBtn"
                            text
                            color="primary"
                            @click="startMenu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            data-cy="okBtn"
                            text
                            color="primary"
                            @click="
                              $refs.startMenu.save(allowanceForm.startDate)
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-menu
                        data-cy="allowance-end-date"
                        ref="endMenu"
                        v-model="endMenu"
                        :close-on-content-click="false"
                        :return-value.sync="allowanceForm.endDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            data-cy="allowance-end-date-input"
                            :rules="[required('End Month')]"
                            outlined
                            dense
                            v-model="allowanceForm.endDate"
                            label="End Month"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          data-cy="allowance-end-date-picker"
                          v-model="allowanceForm.endDate"
                          type="month"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            data-cy="cancelBtn"
                            text
                            color="primary"
                            @click="endMenu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            data-cy="okBtn"
                            text
                            color="primary"
                            @click="$refs.endMenu.save(allowanceForm.endDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-radio-group
                        data-cy="allowance-user-type"
                        v-model="user_type"
                        row
                      >
                        <v-radio
                          data-cy="allowance-user-type-all"
                          label="All Users"
                          value=""
                        ></v-radio>
                        <v-radio
                          data-cy="allowance-user-type-employee"
                          label="Employee"
                          value="employee"
                        ></v-radio>
                        <v-radio
                          data-cy="allowance-user-type-intern"
                          label="Intern"
                          value="intern"
                        ></v-radio>
                        <v-radio
                          data-cy="allowance-user-type-nsp"
                          label="NSP"
                          value="nsp"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        data-cy="allowance-employee"
                        :items="itemsList"
                        item-text="displayName"
                        item-value="employeeId"
                        multiple
                        outlined
                        :rules="[required('At Least One Employee')]"
                        :readonly="editMode"
                        dense
                        v-model="allowanceForm.employeeIds"
                        label="user name"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            data-cy="allowance-select-all"
                            @click="selectAllUsers"
                          >
                            <v-list-item-action
                              data-cy="allowance-select-all-action"
                            >
                              <v-icon
                                :color="
                                  allowanceForm.employeeIds.length > 0
                                    ? '#B94313'
                                    : ''
                                "
                              >
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content
                              data-cy="allowance-select-all-content"
                            >
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-switch
                        data-cy="allowance-taxable"
                        v-model="allowanceForm.taxable"
                        label=" Taxable "
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  data-cy="cancelBtn"
                  color="secondary"
                  type="button"
                  class="text-white text-capitalize"
                  @click="cancelAllowance"
                >
                  CANCEL
                </v-btn>
                <v-btn
                  data-cy="allowance-save"
                  color="primary darken-1"
                  type="submit"
                  class="text-white text-capitalize"
                >
                  {{ btnTitle }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <!-- delete dialog -->
        <v-dialog
          data-cy="allowance-delete-dialog"
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card data-cy="allowance-delete-card">
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                data-cy="cancelBtn"
                color="secondary darken-1"
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                data-cy="okBtn"
                color="primary darken-1"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          data-cy="allowance-edit"
          color="primary"
          dense
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          data-cy="deleteBtn"
          color="primary"
          dense
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-btn
          data-cy="allowance-status"
          d
          small
          :color="setButtonColor(item?.startDate, item?.endDate)"
          @click="progress(item)"
        >
          {{ setProgress(item?.startDate, item?.endDate) }}
        </v-btn>
      </template>
      <template v-slot:[`item.displayName`]="{ item }">
        {{ item.displayName }}
      </template>
      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item.employeeId }}
      </template>
      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 + pageNumbering }}
      </template>
      <template v-slot:[`item.startDate`]="{ item }">{{
        moment(item.startDate)
      }}</template>
      <template v-slot:[`item.endDate`]="{ item }">
        {{ moment(item.endDate) }}
      </template>
      <template v-slot:[`item.totalAllowance`]="{ item }">
        {{ formatFigure(item.totalAllowance) }}
      </template>
      <template v-slot:[`item.monthlyAllowance`]="{ item }">
        {{ formatFigure(item.monthlyAllowance) }}
      </template>

      <template v-slot:no-data> NO DATA AVAILABLE ! </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        data-cy="allowance-pagination"
        v-model="page"
        :length="totalPages"
        @input="paginatePage()"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import helpers from "../services/helpers";
import validation from "../services/validation";
import { isArray } from "@apollo/client/utilities";
import SearchInputAndButton from "../components/UtilityComponents/SearchInputAndButton.vue";
export default {
  mixins: [helpers],
  components: {
    SearchInputAndButton,
  },
  data: () => ({
    ...validation,
    valid: false,
    startMenu: false,
    endMenu: false,
    dialog: false,
    dialogDelete: false,
    isloading: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    itemsPerPage: 20,
    allowanceItem: ["None", 500, "Car Allowance", "Transport"],
    selectName: null,
    headers: [
      { text: "NO.", align: "start", sortable: false, value: "no" },
      {
        text: "EMPLOYEE NAME",
        value: "displayName",
        sortable: false,
        align: "center",
      },
      {
        text: "EMPLOYEE ID",
        value: "employeeId",
        sortable: false,
        align: "center",
      },
      {
        text: "ALLOWANCE NAME",
        value: "description",
        sortable: false,
        align: "center",
      },
      {
        text: "TAXABLE",
        value: "taxable",
        sortable: false,
        align: "center",
      },
      {
        text: "TOTAL AMOUNT",
        value: "totalAllowance",
        sortable: false,
        align: "center",
      },
      {
        text: "MONTHLY ALLOWANCE",
        value: "monthlyAllowance",
        sortable: false,
      },
      {
        text: "START DATE",
        value: "startDate",
        sortable: false,
        align: "center",
      },
      {
        text: "END DATE",
        value: "endDate",
        sortable: false,
        align: "center",
      },
      { text: "STATUS", value: "status", sortable: false, align: "center" },
      { text: "ACTION", value: "actions", sortable: false, align: "center" },
    ],

    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedItem: {},
    editMode: false,
    allowanceForm: new Form({
      id: null,
      startDate: "",
      endDate: "",
      monthlyAllowance: "",
      totalAllowance: "",
      description: null,
      taxable: true,
      employeeIds: [],
    }),
    user_type: "",
  }),
  computed: {
    pageNumbering() {
      if (this.page === 0) {
        return 0;
      } else {
        return this.page * 20 - 20;
      }
    },
    formTitle() {
      return !this.editMode ? "New Allowance" : "Edit Allowance";
    },

    btnTitle() {
      return this.editMode ? "Update" : "Add";
    },

    allowances() {
      return this.$store.getters["additions/allAllowance"];
    },
    totalPages() {
      return this.$store.getters["additions/totalPages"];
    },
    employeeIdsNames() {
      return this.$store.getters["employees/employeeIdsNames"];
    },
    allEmployeeIds() {
      return this.employeeIdsNames.map((user) => {
        return user.employeeId;
      });
    },
    employeesOnly() {
      return this.filterUsers(["SC", "OP", "MG", "TRN"]);
    },
    employeeIds() {
      return this.employeesOnly.map((user) => {
        return user.employeeId;
      });
    },
    internsOnly() {
      return this.filterUsers(["INT"]);
    },
    internIds() {
      return this.internsOnly.map((user) => {
        return user.employeeId;
      });
    },
    nspsOnly() {
      return this.filterUsers(["NSP"]);
    },
    nspIds() {
      return this.nspsOnly.map((user) => {
        return user.employeeId;
      });
    },

    selectAll() {
      if (this.user_type == "employee") {
        return (
          this.allowanceForm.employeeIds.length === this.employeesOnly.length
        );
      } else if (this.user_type == "intern") {
        return (
          this.allowanceForm.employeeIds.length === this.internsOnly.length
        );
      } else if (this.user_type == "nsp") {
        return this.allowanceForm.employeeIds.length === this.nspsOnly.length;
      }
      return (
        this.allowanceForm.employeeIds.length === this.employeeIdsNames.length
      );
    },
    selectSome() {
      return this.allowanceForm.employeeIds.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },

    itemsList() {
      if (this.user_type == "employee") {
        return this.employeesOnly;
      } else if (this.user_type == "intern") {
        return this.internsOnly;
      } else if (this.user_type == "nsp") {
        return this.nspsOnly;
      }
      return this.employeeIdsNames;
    },

    page: {
      get() {
        return this.$store.getters["additions/currentPage"];
      },
      set(val) {
        return this.$store.commit("additions/SET_CURRENT_PAGE", val - 1);
      },
    },
    tags() {
      let allTags = this.$store.getters["additions/tags"];
      return allTags.filter((tag) => {
        return tag.tagType === "Allowance";
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    filterUsers(key) {
      return this.employeeIdsNames.filter((user) => {
        const keyArray = isArray(key) ? key : [key];
        const departmentInitials = user.employeeId.split(" ")[0].split("-")[0];
        return keyArray.includes(departmentInitials);
      });
    },
    selectAllUsers() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.allowanceForm.employeeIds = [];
        } else {
          switch (this.user_type) {
            case "employee":
              this.allowanceForm.employeeIds = this.employeeIds;
              break;
            case "intern":
              this.allowanceForm.employeeIds = this.internIds;
              break;
            case "nsp":
              this.allowanceForm.employeeIds = this.nspIds;
              break;
            default:
              this.allowanceForm.employeeIds = this.allEmployeeIds;
          }
        }
      });
    },
    getEmployeeName(id) {
      return (
        this.employeeIdsNames.find((user) => {
          return user.employeeId === id;
        }).displayName ?? null
      );
    },
    formatFigure(item) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    },
    getEmployeeNumber(id) {
      let employee = this.employeeIdsNames.find((user) => {
        return user.employeeId === id;
      });
      return employee ? employee.employeeNumber : "no number";
    },
    moment(date) {
      return moment(date).format("MMM YYYY");
    },
    setProgress(startDate, endDate) {
      const currentDate = new Date();
      const [currentYear, currentMonth] = currentDate
        .toISOString()
        .slice(0, 7)
        .split("-");
      const [startYear, startMonth] = startDate.split("-");
      const [endYear, endMonth] = endDate.split("-");

      if (
        currentYear < startYear ||
        (currentYear === startYear && currentMonth < startMonth)
      ) {
        return "Pending";
      } else if (
        currentYear < endYear ||
        (currentYear === endYear && currentMonth <= endMonth)
      ) {
        return "In Progress";
      } else {
        return "Completed";
      }
    },

    setButtonColor(startDate, endDate) {
      const status = this.setProgress(startDate, endDate);
      switch (status) {
        case "In Progress":
          return "success";
        case "Pending":
          return "#2026000";
        case "Completed":
          return "#ff6f3f";
        default:
          return "primary";
      }
    },

    async initialize() {
      this.isloading = true;
      await this.$store.dispatch("additions/fetchAllowance", 1);
      this.isloading = false;
    },

    editItem(item) {
      let data = {
        id: item.id,
        startDate: item.startDate,
        endDate: item.endDate,
        monthlyAllowance: item.monthlyAllowance.toString(),
        totalAllowance: item.totalAllowance.toString(),
        description: item.description,
        taxable: item.taxable,
        employeeIds: [],
      };
      data.employeeIds.push(item.employeeId);
      this.dialog = true;
      this.allowanceForm.fill(data);
      this.editMode = true;
    },

    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store
        .dispatch("additions/deleteAllowance", this.selectedItem.id)
        .then(() => {
          this.selectedItem = {};
          this.closeDelete();
          this.isError = false;
          this.snackBarText = " Allowance Deleted Successfully";
          this.snackBar = true;
          this.initialize();
        })
        .catch(() => {
          this.selectedItem = {};
          this.closeDelete();
          this.isError = true;
          this.snackBarText = "Error Occurred While Deleting The Item";
          this.snackBar = true;
          this.initialize();
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    cancelAllowance() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editMode = false;
        this.allowanceForm.reset();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    submitAllowance() {
      // form validation
      if (!this.$refs.allowanceForm.validate()) return;

      //amount validation against monthly and total value
      //get months difference
      let monthDiff = this.getMonthsDifference(
        this.allowanceForm.startDate,
        this.allowanceForm.endDate
      );

      this.allowanceForm.monthlyAllowance =
        this.allowanceForm.totalAllowance / monthDiff;
      this.isloading = true;

      let data = [];
      this.allowanceForm.employeeIds.map((item) => {
        data.push({
          id: this.allowanceForm.id,
          startDate: this.allowanceForm.startDate,
          endDate: this.allowanceForm.endDate,
          monthlyAllowance: parseFloat(this.allowanceForm.monthlyAllowance),
          totalAllowance: parseFloat(this.allowanceForm.totalAllowance),
          description: this.allowanceForm.description,
          taxable: this.allowanceForm.taxable,
          employeeId: item,
        });
      });
      this.dialog = false;
      if (this.editMode) {
        this.$store
          .dispatch("additions/editAllowance", {
            ...data[0],
            displayName: this.getEmployeeName(data[0].employeeId),
          })
          .then(() => {
            this.isloading = false;
            this.snackBarText = "Allowance Updated Successfully";
            this.dialog = false;
            this.isError = false;
            this.snackBar = true;
          })
          .catch(() => {
            this.isloading = false;
            this.snackBarText = "Error Occurred While Updating Allowance";
            this.dialog = false;
            this.isError = true;
            this.snackBar = true;
          });
      } else {
        this.$store
          .dispatch("additions/createAllowance", data)
          .then(() => {
            this.isloading = false;
            this.snackBarText = "Allowance Created Successfully";
            this.dialog = false;
            this.isError = false;
            this.snackBar = true;
          })
          .catch(() => {
            this.isloading = false;
            this.snackBarText = "Error Occurred While Creating Allowance";
            this.dialog = false;
            this.isError = true;
            this.snackBar = true;
          });
      }
    },
    addNewItem() {
      this.allowanceForm.reset();
      this.editMode = false;
      this.dialog = true;
    },
    async searchAllowance(query) {
      if (query) {
        this.isloading = true;
        await this.$store.dispatch("additions/searchAllowances", query);
        this.isloading = false;
      } else {
        this.snackBar = true;
        this.snackBarText = " Search input cannot be empty";
      }
    },

    async resetData() {
      await this.initialize();
    },

    async paginatePage() {
      this.isloading = true;
      this.scrollToTop();
      await this.$store.dispatch("additions/fetchAllowance", this.page);
      this.isloading = false;
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },

  created() {
    this.initialize();
  },
};
</script>

<style scoped>
.col-12,
.col.sm-12,
.col-md-12 {
  padding: 0 10px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.875rem;
  height: 64px;
}
</style>
